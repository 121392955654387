import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import './FormBuilderStyles.css';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL; // e.g., 'https://ccportalapi.connectcanada.com'
const AUTH_URL = process.env.REACT_APP_AUTH_BASE_URL; // e.g., 'https://your-auth-url.com'


const FormBuilder = ({ formConfig, applicationDoc }) => {
    const [initialValues, setInitialValues] = useState({});
    const [validationSchema, setValidationSchema] = useState(Yup.object({}));
    const [submittedFields, setSubmittedFields] = useState({});
    const [relatedRecResponse, setRelatedRecResponse] = useState({});
    const [loadingFields, setLoadingFields] = useState({});
    const [recordData, setRecordData] = useState(applicationDoc);
    const [dropdownValues, setDropdownValues] = useState(() => {
        const initialDropdownValues = {};
        formConfig.sections.forEach((section) => {
            section.items.forEach((item) => {
                if (item.fields_type === 'picklist' || item.fields_type === 'checkbox') {
                    let defVal = item.items && item.items.length > 0 ? item.items[0] : 'None';
                    if (item.id in recordData) {
                        defVal = recordData[item.id]?.values || 'None';
                    } else if (item.defaultValue) {
                        defVal = item.defaultValue;
                    }
                    initialDropdownValues[item.id] = defVal;
                }
            });
        });
        // console.log("initialDropdownValues : ", initialDropdownValues);
        return initialDropdownValues;
    });
    const location = useLocation();
    const fileInputRefs = useRef({});
    const [tooltipStyle, setTooltipStyle] = useState({});
    const tooltipRef = useRef(null);
    const debounceTimeout = useRef(null);

    // Function to process items and build initialValues and validationSchemaFields
    const processItems = (items, initialValuesObj, validationSchemaFieldsObj) => {
        items.forEach((item) => {
            if (item.fields_type === 'picklist' || item.fields_type === 'checkbox') {
                // Initialize dropdown value
                initialValuesObj[item.id] = 'None';
                // validationSchemaFieldsObj[item.id] = Yup.string().required('Required');
                // If there's a default value, set it
                if (item.defaultValue) {
                    initialValuesObj[item.id] = item.defaultValue;
                    setDropdownValues((prev) => ({ ...prev, [item.id]: item.defaultValue }));
                }
                // Process conditional fields recursively if a value is selected
                if (dropdownValues[item.id] && item.conditional_fields) {
                    const selectedValue = dropdownValues[item.id];
                    if (item.conditional_fields[selectedValue]) {
                        processItems(item.conditional_fields[selectedValue], initialValuesObj, validationSchemaFieldsObj);
                    }
                }
            } else {
                initialValuesObj[item.statusId] = null;
                // validationSchemaFieldsObj[item.statusId] = Yup.mixed().required(`${item.label} is required`);
            }
        });
    };

    useEffect(() => {
        const fetchStatuses = async () => {
            try {
                const relatedRecResponse = await getRelatedRecords(location);
                const response = {};
                const query = new URLSearchParams(location.search);
                const portal_id = query.get("portal");
                for (let index = 0; index < relatedRecResponse.length; index++) {
                    const element = relatedRecResponse[index];
                    const itemId = element.Item_ID;
                    const Id = element.id;
                    response[itemId] = Id;
                }
                response["portal_id"] = portal_id;
                setRelatedRecResponse(response);
                const statusUpdates = {};

                relatedRecResponse.forEach((element) => {
                    const itemId = element.Item_ID;
                    const status = element.Status;

                    if (status === "Pending Review") {
                        const pSharingLink = element.External_Link_Pending_Review;
                        statusUpdates[itemId] = [status, pSharingLink];
                    } else if (status === "Rejected") {
                        const rSharingLink = element.External_Link_Reject;
                        const rejectedReason = element.Rejected_Reason;
                        statusUpdates[itemId] = [`${status} Reason: ${rejectedReason}`, rSharingLink];
                    } else if (status === "Accepted") {
                        statusUpdates[itemId] = [status, ""];
                    }
                });

                setSubmittedFields(statusUpdates);
            } catch (error) {
                console.error("Error fetching statuses:", error);
            }
        };
        fetchStatuses();
    }, [location]);

    const openPreviewFile = async (id, status, link) => {
        if (link === "New Submit") {
            alert("Preview is currently generating.. Please reload in sometime");
        } else {
            try {
                window.open(
                    link,
                    '_blank',
                    'popup=yes,width=800,height=600,left=100,top=100,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=yes,location=yes'
                );
            } catch (error) {
                console.error("Error opening preview ", error);
            }
        }
    };

    const handleSubmitField = async (id, file, status = "Pending Review") => {
        setLoadingFields((prevLoading) => ({ ...prevLoading, [id]: true }));
        const MIN_LOADING_TIME = 3000;
        const startTime = Date.now();

        try {
            if (id in relatedRecResponse) {
                const rel_rec_id = relatedRecResponse[id];
                const response_list = await Promise.all(Array.from(file).map((f) => updateAttachment(rel_rec_id, [f])));

                const allOk = response_list.every((res) => res?.status === 200 && res.details[Object.keys(res.details)[0]].Status_Code === 200);

                if (allOk) {
                    const update_list = [{ id: rel_rec_id, Status: "Pending Review" }];
                    await updateRecord(update_list);

                    const elapsedTime = Date.now() - startTime;
                    if (elapsedTime < MIN_LOADING_TIME) {
                        await new Promise((resolve) => setTimeout(resolve, MIN_LOADING_TIME - elapsedTime));
                    }
                    setSubmittedFields((prevFields) => ({ ...prevFields, [id]: [status, "New Submit"] }));
                } else {
                    alert("File Upload failed. Please contact the administrator.");
                }
            } else {
                alert("File Upload failed. Please contact the administrator.");
            }
        } catch (error) {
            console.error(`Error submitting field ${id}:`, error);
            alert(`Error submitting field ${id}:`, error);
        } finally {
            setLoadingFields((prevLoading) => ({ ...prevLoading, [id]: false }));
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "Pending Review":
                return "status-yellow";
            case "Rejected":
                return "status-red";
            case "Accepted":
                return "status-green";
            default:
                return "";
        }
    };

    const handleTooltipHover = (e, item) => {
        const rect = e.target.getBoundingClientRect();
        const tooltipHeight = tooltipRef.current.offsetHeight;
        const tooltipWidth = tooltipRef.current.offsetWidth;
        const left = (window.innerWidth / 2) - (tooltipWidth / 2);
        const top = rect.top - tooltipHeight - 10;

        setTooltipStyle({ left: `${left}px`, top: `${top}px` });
        tooltipRef.current.className = "tooltiptext";
    };

    // Handle dropdown changes
    const handleDropdownChange = (dropdownId, value, user_visibility) => {
        if (user_visibility === "No") {
            return
        }
        clearTimeout(debounceTimeout.current);
        if (value !== recordData[dropdownId]?.values) {

            debounceTimeout.current = setTimeout(() => {
                const query = new URLSearchParams(location.search);
                const portal_id = query.get("portal");
                const update_list = [{ id: portal_id, [dropdownId]: value == "" ? "None" : value }];
                // console.log(update_list);
                updateRecord(update_list, "Cases");
                debounceTimeout.current = null;
            }, 7000);
        }
        setDropdownValues((prev) => ({
            ...prev,
            [dropdownId]: value,
        }));
    };

    // Helper function to render form fields
    const renderFieldInputAndButtons = (item, setFieldValue, values, errors, touched) => {
        const isSubmitted = submittedFields[item.statusId];
        const statusInfo = isSubmitted ? submittedFields[item.statusId] : null;
        const isLoading = loadingFields[item.statusId];

        return (
            <>
                {!isSubmitted ? (
                    item["form-link"] ? (
                        <div className='form-group-base'>
                            <a
                                href={`${item["form-link"]}/?hash=${relatedRecResponse[item.statusId]}&portal=${relatedRecResponse["portal_id"]}&emails=${getEmail()}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button type="button" className="form-button">Open Form</button>
                            </a>
                        </div>
                    ) : (
                        <>
                            <input
                                ref={(el) => (fileInputRefs.current[item.statusId] = el)}
                                id={item.statusId}
                                name={item.statusId}
                                type="file"
                                multiple
                                accept=".pdf, .heic, .jpeg, .jpg, .png, .xls, .xlsx, .doc, .docx"
                                disabled={isLoading}
                                onChange={(event) => setFieldValue(item.statusId, event.currentTarget.files)}
                                className="file-input"
                            />
                            {touched[item.statusId] && errors[item.statusId] && (
                                <div className="error-message">{errors[item.statusId]}</div>
                            )}
                            {values[item.statusId] && (
                                <>
                                    {isLoading ? (
                                        <div className="button-loader"></div>
                                    ) : (
                                        <div className="button-group">
                                            <button
                                                type="button"
                                                onClick={() => handleSubmitField(item.statusId, values[item.statusId])}
                                                className="submit-button"
                                            >
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setFieldValue(item.statusId, null);
                                                    if (fileInputRefs.current[item.statusId]) {
                                                        fileInputRefs.current[item.statusId].value = "";
                                                    }
                                                }}
                                                className="clear-button"
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )
                ) : (
                    <>
                        {statusInfo[0].includes("Rejected") ? (
                            <>
                                {item["form-link"] ? (
                                    <div className='form-group-base'>
                                        <a
                                            href={`${item["form-link"]}/?hash=${relatedRecResponse[item.statusId]}&portal=${relatedRecResponse["portal_id"]}&emails=${getEmail()}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <button type="button" className="form-button">Open Form</button>
                                        </a>
                                    </div>
                                ) : (
                                    <>
                                        <input
                                            ref={(el) => (fileInputRefs.current[item.statusId] = el)}
                                            id={item.statusId}
                                            name={item.statusId}
                                            type="file"
                                            multiple
                                            accept=".pdf, .heic, .jpeg, .jpg, .png, .xls, .xlsx, .doc, .docx"
                                            disabled={isLoading}
                                            onChange={(event) => setFieldValue(item.statusId, event.currentTarget.files)}
                                            className="file-input"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => openPreviewFile(item.statusId, values[item.statusId], statusInfo[1])}
                                            className="preview-button"
                                        >
                                            Preview
                                        </button>
                                    </>
                                )}
                                <div className={`status-message ${getStatusColor("Rejected")}`}>
                                    Status: {statusInfo[0]}
                                </div>
                                {touched[item.statusId] && errors[item.statusId] && (
                                    <div className="error-message">{errors[item.statusId]}</div>
                                )}
                                {values[item.statusId] && (
                                    <>
                                        {isLoading ? (
                                            <div className="button-loader"></div>
                                        ) : (
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    onClick={() => handleSubmitField(item.statusId, values[item.statusId])}
                                                    className="submit-button"
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setFieldValue(item.statusId, null);
                                                        if (fileInputRefs.current[item.statusId]) {
                                                            fileInputRefs.current[item.statusId].value = "";
                                                        }
                                                    }}
                                                    className="clear-button"
                                                >
                                                    Clear
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            statusInfo[0].includes("Accepted") ? (
                                <>
                                    <div className={`status-message ${getStatusColor(statusInfo[0])}`}>
                                        Status: {statusInfo[0]}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {!item["form-link"] && (
                                        <button
                                            type="button"
                                            onClick={() => openPreviewFile(item.statusId, values[item.statusId], statusInfo[1])}
                                            className="preview-button"
                                        >
                                            Preview
                                        </button>
                                    )}
                                    <div className={`status-message ${getStatusColor(statusInfo[0])}`}>
                                        Status: {statusInfo[0]}
                                    </div>
                                </>
                            )
                        )}
                    </>
                )
                }
            </>
        );
    };

    // Helper function to render form sections
    const renderFormSection = (section, setFieldValue, values, errors, touched) => {
        // console.log("Section Items ", section.items.length, section.items);
        const countVisibleFields = (section) => {
            return section.items.reduce((total, item) => {
                let fieldCount = 0;
                // Count regular input fields
                if (!item.fields_type) {
                    fieldCount++;
                }
                // Count input fields in picklist conditional fields
                else if (item.fields_type === "picklist" && item.conditional_fields) {
                    const selectedValue = dropdownValues[item.id];
                    // console.log("selectedValue", selectedValue);
                    // console.log("visibleFields", item.conditional_fields[selectedValue]);
                    if (item.user_visibility == "Yes") {
                        fieldCount++;
                        if (selectedValue === "None") {
                            fieldCount++;
                        } else {

                            const visibleFields = item.conditional_fields[selectedValue] || [];
                            // console.log(visibleFields);
                            // Filter for only input fields (assuming fields without `fields_type` are inputs)

                            const visibleInputFields = visibleFields.filter((field) => !field.fields_type);
                            fieldCount += visibleInputFields.length;
                        }
                    } else {

                    }
                }
                else if (item.fields_type === 'checkbox' && item.conditional_fields) {
                    const selectedValue = dropdownValues[item.id];
                    // console.log("selectedValue", selectedValue);
                    // console.log("visibleFields", item.conditional_fields[selectedValue]);
                    if (item.user_visibility == "No") {


                        const visibleFields = item.conditional_fields[selectedValue] || [];
                        // console.log(visibleFields);
                        // Filter for only input fields (assuming fields without `fields_type` are inputs)

                        const visibleInputFields = visibleFields.filter((field) => !field.fields_type);
                        fieldCount += visibleInputFields.length;

                    }
                }

                return total + fieldCount;
            }, 0);
        };

        let inpFieldsCount = countVisibleFields(section);
        // console.log("inpFieldsCount : ", inpFieldsCount);
        return (
            (inpFieldsCount === 0) ? (
                <>
                </>
            ) : (
                <>
                    <div key={section.id} className="form-section">
                        <h3 >
                            {section.header}
                            {section.hint && (
                                <div
                                    className="tooltip"
                                    onMouseEnter={(e) => handleTooltipHover(e, section)}
                                >
                                    <span className="tooltip-icon">ℹ️</span>
                                    <span
                                        ref={tooltipRef}
                                        className="tooltiptext"
                                        style={tooltipStyle}
                                        dangerouslySetInnerHTML={{ __html: section.hint }}
                                    />
                                </div>
                            )}
                            {section['imp-link'] && (
                                <a href={section['imp-link']} target="_blank" className="link-icon" rel="noopener noreferrer">
                                    <span className="link-icon-image">🔗</span>
                                </a>
                            )}
                        </h3>
                        {section.items.map((item) => {
                            if (item && typeof item === 'object') {
                                if (item.fields_type === 'picklist' || item.fields_type === 'checkbox') {
                                    return renderDropdownField(item, setFieldValue, values, errors, touched);
                                } else if (item.fields_type === 'header') {
                                    return renderHeader(item, setFieldValue, values, errors, touched);
                                } else {
                                    return renderFormItem(item, setFieldValue, values, errors, touched);
                                }
                            }
                            return null;
                        })}
                    </div>
                </>
            )

        );
    };

    // Helper function to render header

    const renderHeader = (item, setFieldValue, values, errors, touched) => {
        return (
            <h3 htmlFor={item.id}>{item.label}</h3>
        );
    };

    // Helper function to render dropdown fields
    const renderDropdownField = (item, setFieldValue, values, errors, touched) => {
        // console.log("Item ", item);
        return (
            <div key={item.id} className="form-group">
                <label class={item.user_visibility === "Yes" ? "" : "hide-imp"} htmlFor={item.id}>{item.label}</label>
                <Field
                    as="select"
                    name={item.id}
                    className={item.user_visibility === "Yes" ? "form-control" : "hide-imp"}
                    value={dropdownValues[item.id] || ''}
                    onChange={(e) => {
                        handleDropdownChange(item.id, e.target.value, item.user_visibility);
                        setFieldValue(item.id, e.target.value);
                    }}
                >
                    {item.items.map((option) => (
                        option === "None" ? (
                            <option key={option} value="">
                                {option}
                            </option>
                        ) : (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        )

                    ))}
                </Field>
                {touched[item.id] && errors[item.id] && (
                    <div className="error-message">{errors[item.id]}</div>
                )}
                    {/* Render Conditional Fields */}
                    {dropdownValues[item.id] &&
                    dropdownValues[item.id] !== "None" &&
                    item.conditional_fields && (
                        <div key={`conditional-${item.id}-${dropdownValues[item.id]}`}>
                            {renderConditionalFields(
                                item.conditional_fields[dropdownValues[item.id]],
                                setFieldValue,
                                values,
                                errors,
                                touched
                            )}
                        </div>
                    )}
            </div>
        );
    };

    // Helper function to render conditional fields
    const renderConditionalFields = (fields, setFieldValue, values, errors, touched) => {
        return fields.map((field) => {
            if (field.fields_type === 'picklist' || field.fields_type === 'checkbox') {
                return renderDropdownField(field, setFieldValue, values, errors, touched);
            } else if (field.fields_type === 'header') {
                return renderHeader(field, setFieldValue, values, errors, touched);
            } else {
                return renderFormItem(field, setFieldValue, values, errors, touched);
            }
        });
    };

    // Helper function to render form items
    const renderFormItem = (item, setFieldValue, values, errors, touched) => (
        <div key={item.statusId} className="form-group">
            <label htmlFor={item.statusId}>
                {item.label}
                {item.hint && (
                    <div
                        className="tooltip"
                        onMouseEnter={(e) => handleTooltipHover(e, item)}
                    >
                        <span className="tooltip-icon">ℹ️</span>
                        <span
                            ref={tooltipRef}
                            className="tooltiptext"
                            style={tooltipStyle}
                            dangerouslySetInnerHTML={{ __html: item.hint }}
                        />
                    </div>
                )}
                {item["imp-link"] && (
                    <a href={item['imp-link']} target="_blank" className="link-icon" rel="noopener noreferrer">
                        <span className="link-icon-image">🔗</span>
                    </a>
                )}
            </label>
            {renderFieldInputAndButtons(item, setFieldValue, values, errors, touched)}
        </div>
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => console.log('Form data', values)}
            enableReinitialize
        >
            {({ setFieldValue, values, errors, touched }) => (
                <Form className="form-builder">
                    {/* Render Form Sections */}
                    {formConfig.sections.map((section) =>
                        renderFormSection(section, setFieldValue, values, errors, touched)
                    )}

                </Form>
            )}
        </Formik>
    );
};


function getEmail() {
    // Retrieve the token from cookies
    const token = Cookies.get('token');
    if (!token) {
        alert('Authentication required. Redirecting to login...');
        window.location.href = AUTH_URL; // Ensure AUTH_URL is defined
        return;
    }

    let decodedToken;

    try {
        // Decode the token
        decodedToken = jwtDecode(token);
        const userId = decodedToken?.user_id;
        // console.log("User ID : ", userId);
        return userId;
    } catch (error) {
        alert('Something isnt right. Redirecting to login...');
        window.location.href = AUTH_URL; // Ensure AUTH_URL is defined
        return;
    }
}
async function updateAttachment(recId, file) {
    const randomCode = generateRandomCode(40);
    // console.log(randomCode);
    // console.log(file);

    const formData = new FormData();
    if (file.length > 0) {
        for (const f of file) {
            formData.append('file', f);
        }
    }

    formData.append('api_name', "uploadAttachment");
    formData.append('batch_id', randomCode);
    formData.append('module_name', "CC_Portal_Items");
    formData.append('portal_hash', recId);
    for (var pair of formData.entries()) {
        // console.log(pair[0] + ': ' + pair[1]); // For files, pair[1] will be the File object
    }

    const apiUrl = `${API_BASE_URL}/uploadAttachment`;

    try {
        let response = await fetch(apiUrl, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            body: formData,
            redirect: "follow"
        });

        if (response.status === 401) {
            alert("Session expired. Redirecting to login...");
            window.location.href = AUTH_URL;
            return;
        }

        let response_data = await readStream(response.body);
        // console.log("response_data Get Record");
        // console.log(response_data);
        const resp = response_data[Object.keys(response_data)[0]];
        // console.log(resp);
        if (response_data.status == 400) {
            return { "status": 400, "details": response_data.error };
        }
        return { "status": 200, "details": response_data };
    } catch (error) {
        // console.log('There was a problem with the fetch operation:', error);
        return { "status": 400, "details": error };
    }
}

async function readStream(stream) {
    const reader = stream.getReader();
    const decoder = new TextDecoder();
    let result;
    let data = '';
    try {
        while (!(result = await reader.read()).done) {
            data += decoder.decode(result.value, { stream: true });
        }
        data += decoder.decode(); // Decode any remaining bytes
        return JSON.parse(data);
    } catch (error) {
        console.error('Error reading and parsing stream as JSON:', error);
        throw error;
    } finally {
        reader.releaseLock();
    }
}

async function getRelatedRecords(location) {
    const query = new URLSearchParams(location.search); // Get params directly using location.search
    const portal_id = query.get("portal");
    const portal_item_id = query.get("portalItem");

    // console.log("Related Portal ID: ", portal_id);
    // console.log("Related Portal Item ID: ", portal_item_id);
    const randomCode = generateRandomCode(40);
    const requestBody = {
        api_name: "getRelatedRecord",
        batch_id: randomCode,
        module_name: "Cases",
        portal_hash: portal_id,
        related_module_name: "Portal_Items"
    };

    const apiUrl = `${API_BASE_URL}/getRelatedRecord`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        if (response.status === 401) {
            alert("Session expired. Redirecting to login...");
            window.location.href = AUTH_URL;
            return;
        }

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await readStream(response.body);
        // console.log("Response Data:", responseData);

        if (responseData && responseData.record_lsit) {
            return responseData.record_lsit;
        }

    } catch (error) {
        console.error('Fetch operation failed:', error);
    }
}

async function updateRecord(update_list, module_name = "CC_Portal_Items") {
    const randomCode = generateRandomCode(40);
    const apiUrl = `${API_BASE_URL}/updateRecord`;
    const requestData = {
        api_name: "updateRecord",
        batch_id: randomCode,
        module_name: module_name,
        update_records_list: update_list
    };
    // console.log("UPDATE Request Data : ", requestData);

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        if (response.status === 401) {
            alert("Session expired. Redirecting to login...");
            window.location.href = AUTH_URL;
            return;
        }

        const responseData = await readStream(response.body);
        // console.log("Response Data:", responseData);
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

// Ensure generateRandomCode function is defined
function generateRandomCode(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default FormBuilder;